<template>
  <CardTable
    :title="title"
    :columns="table.columns"
    :rows="table.rows"
    :pagination="table.pagination"
  >
    <template #cell(period)="data">
      {{ `${parseMonth(data.item.periodMonth)}/${data.item.periodYear}` }}
    </template>
    <template #cell(paymentDueDate)="data">
      {{ dateFormat(data.item.paymentDueDate) }}
    </template>
    <template #cell(amount)="data">
      {{ numberFormat(data.item.amount) }}
    </template>
    <template #cell(outstandingBalance)="data">
      {{ numberFormat(data.item.outstandingBalance) }}
    </template>
    <template slot="emptymessage">
      <p class="mt-4">
        There are no unpaid Invoices for this account, that can be applied for
        this payment.
      </p>
    </template>
  </CardTable>
</template>

<script>
import {
  dateFormat,
  dateTimeFormat,
  numberFormat,
  parseMonth,
} from "@/shared/utils";
import { unpaidInvoice as columns } from "@/shared/columns";

export default {
  props: {
    accountId: String,
    invoiceNums: Array,
  },
  /* watch: {
    accountId(newValue, oldValue) {
      const self = this;
      self.getInvoices({
        accountId: newValue,
        paymentStatus: [0, 1],
        orderBy: "periodMonth desc,periodYear desc",
      });
    },
    invoiceNums(newValue, oldValue) {
      const self = this;
      self.getInvoices({
        invoiceNums: newValue,
        orderBy: "periodMonth desc,periodYear desc",
      });
    },
  }, */
  data: () => ({
    route: {
      form: "PaymentsCreate",
    },
    title: "Unpaid Invoices",
    table: {
      isLoading: true,
      columns,
      rows: [],
      pagination: false,
    },
  }),
  methods: {
    dateFormat,
    dateTimeFormat,
    numberFormat,
    parseMonth,
    getInvoices(params) {
      const self = this;

      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: "/transaction/invoice",
          params: params,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.$emit("onPopulate", self.table.rows, response.data.additionalData);
          }
          self.table.isLoading = false;
        });
    },
  },
};
</script>

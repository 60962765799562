<template>
  <b-form @submit.stop.prevent="handleSubmit">
    <b-card>
      <div slot="header">
        <strong>
          {{
            $route.name == route.form ? "Create New Payment" : "Update Payment"
          }}
        </strong>
        <div v-if="$route.name != route.form" class="card-header-actions">
          <b-button v-if="form.editable"
                    size="sm"
                    variant="danger"
                    v-b-tooltip.hover
                    title="Delete Payment"
                    @click="handleDelete">
            <i class="icon-trash"></i>
          </b-button>
        </div>
      </div>
      <b-row>
        <!--building selection-->
        <BuildingDropdown :server-params="form"
                          :form-group="true"
                          :on-select="getAccounts" />
        <b-col sm="12">
          <form-group :validator="$v.form.accountId"
                      label="Account"
                      :use-horizontal="false">
            <treeselect v-model="form.accountId"
                        placeholder="Select Account"
                        :options="options.account"
                        :clearable="false"
                        :disabled="$route.name != route.form"
                        @input="changeAccountId" />
            <div>
              <p><b>Outstanding Invoice Amount</b></p>
              <p>Outstanding Amount (Excluding Deposit): ${{form.amountDisplay}}</p>
              <p>Additional Required Deposit: ${{form.additionalRequiredDepositDisplay}}</p>
            </div>
          </form-group>
          <form-group :validator="$v.form.paymentDate"
                      label="Payment Date"
                      :use-horizontal="false">
            <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                               locale="en-sg"
                               v-model="form.paymentDate"></b-form-datepicker>
          </form-group>
          <form-group :validator="$v.form.amount"
                      label="Payment Amount (SGD)"
                      :use-horizontal="false">
            <vue-numeric-input v-model="form.amount"
                               :step="2"
                               :controls="false"
                               className="form-control"
                               width="100%"></vue-numeric-input>
          </form-group>
          <!--depoist amount-->
          <form-group :validator="$v.form.additionalRequiredDeposit"
                      label="Payment for Deposit (SGD)"
                      :use-horizontal="false">
            <vue-numeric-input v-model="form.additionalRequiredDeposit"
                               :step="2"
                               :controls="false"
                               className="form-control"
                               width="100%"></vue-numeric-input>
          </form-group>
          <form-group :validator="$v.form.paymentMethod"
                      label="Payment Method"
                      :use-horizontal="false">
            <treeselect v-model="form.paymentMethod"
                        placeholder="Select Payment Method"
                        :options="options.paymentMethod"
                        :clearable="false" />
          </form-group>
          <form-group label="Remarks" :use-horizontal="false">
            <b-textarea v-model="form.remarks"></b-textarea>
          </form-group>
        </b-col>
      </b-row>
      <div slot="footer">
        <b-button v-if="!form.isUpdate" @click="$router.go(-1)" size="sm">
          Back
        </b-button>
        <b-button type="submit" size="sm" variant="success" class="float-right">
          {{ $route.name == route.form ? "Submit" : "Save Changes" }}
        </b-button>
      </div>
    </b-card>
  </b-form>
</template>

<script>
  import { required } from "vuelidate/lib/validators";
  import { paymentMethod } from "@/shared/options";
  import BuildingDropdown from "@/components/common/BuildingDropdown";

  export default {
    props: {
      form: Object,
    },
    data: () => ({
      route: {
        form: "PaymentsCreate",
      },
      options: {
        account: [],
        paymentMethod,
      },
    }),
    validations: {
      form: {
        accountId: { required },
        paymentDate: { required },
        amount: { required },
        paymentMethod: { required },
      },
    },
    components: {
      BuildingDropdown,
    },
    created() {
      this.getAccounts();
    },
    methods: {
      getAccounts() {
        const self = this;
        self.$store
          .dispatch("apis/get", {
            url: "/account",
            params: {
              buildingId: self.form.buildingId,
            },
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.options.account = response.data.data.map((x) => ({
                id: x.accountId,
                label: `${x.accountId}# ${x.name}`,
              }));
            }
          });
      },
      handleSubmit() {
        const self = this;

        self.$v.form.$touch();
        if (self.$v.form.$pending || self.$v.form.$error) return;

        let _confirmText = "",
          _okText = "",
          _action = "",
          _url = "";

        if (self.$route.name == self.route.form) {
          _confirmText = "You are about to submit this payment. Are you sure ?";
          _okText = "Yes, Submit";
          _action = "apis/post";
          _url = "/transaction/payment";
        } else {
          _confirmText = "You are about to update this payment. Are you sure ?";
          _okText = "Yes, Update";
          _action = "apis/put";
          _url = `/transaction/payment/${self.$route.params.id}`;
        }

        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: self.form,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },
      handleDelete() {
        const self = this;

        self.$dialog
          .confirm(
            "You are about to delete this payment record. Are you sure ?",
            {
              okText: "Yes, Delete",
              cancelText: "Cancel",
              loader: true,
            }
          )
          .then((dialog) => {
            self.$store
              .dispatch("apis/remove", {
                url: `/transaction/payment/${self.$route.params.id}?buildingId=${self.form.buildingId}`,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },
      changeAccountId() {
        const self = this;
        if (self.$route.name == self.route.form) {
          self.$emit("onChange");
        }
      },
    },
  };
</script>

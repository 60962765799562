<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col cols="5">
        <RecordPayment :form="form" @onChange="getOutstanding" />
      </b-col>
      <b-col cols="7">
        <!--<UnpaidInvoice ref="unpaidInvoice" @onPopulate="onPopulate" />-->
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import RecordPayment from "./RecordPayment.vue";
  import UnpaidInvoice from "./UnpaidInvoice.vue";
  import { getDate, dateFormat } from "@/shared/utils";

  export default {
    components: {
      RecordPayment,
      UnpaidInvoice,
    },
    data: () => ({
      route: {
        form: "PaymentsCreate",
        table: "Payments",
      },
      form: {
        accountId: null,
        buildingId: null,
        paymentDate: getDate(),
        amount: 0,
        amountDisplay: 0,
        paymentMethod: null,
        remarks: null,
        editable: true,
        additionalRequiredDeposit: 0,
        additionalRequiredDepositDisplay: 0,
      },
      invoiceNums: null,
    }),
    created() {
      const self = this;
      if (self.$route.name != self.route.form) {
        self.get();
      }
    },
    methods: {
      getDate,
      onPopulate(params, additionalData) {
        const self = this;
        self.form.additionalRequiredDepositDisplay = additionalData.additionalRequiredDeposit;
        //additional required deposit
        if (self.$route.name == self.route.form) {
          /*const sum = params
            .map((item) => item.outstandingBalance)
            .reduce((prev, curr) => prev + curr, 0);*/
          const sum = params.length > 0 ? params.map((item) => item.outstandingBalance)[0] : 0;
          self.form.additionalRequiredDeposit = additionalData.additionalRequiredDeposit;
          if (sum >= 0) self.form.amount = sum + additionalData.adjustmentAmount;
        }
      },
      get() {
        const self = this;

        let loader = self.$loading.show();
        self.$store
          .dispatch("apis/get", {
            url: `/transaction/payment/${self.$route.params.id}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });

              self.$router.push({ name: self.route.table });
            } else {
              self.form = {
                accountId: response.data.account.accountId,
                buildingId: response.data.account.buildingId,
                paymentDate: response.data.paymentDate, // dateFormat(response.data.paymentDate, "DD-MM-YYYY"),
                amount: response.data.amount,
                amountDisplay: response.data.amount,
                paymentMethod: response.data.paymentMethod != null ? response.data.paymentMethod.id : null,
                remarks: response.data.remarks,
                additionalRequiredDeposit: response.data.depositAmount,
                additionalRequiredDepositDisplay: response.data.depositAmount,
                editable: response.data.editable,
              };
              self.invoiceNums = response.data.invoices;
              //self.getInvoices();
            }
            loader.hide();
          });
      },
      getInvoices() {
        const self = this;
        if (self.$route.name == self.route.form) {
          self.$refs.unpaidInvoice.getInvoices({
            accountId: self.form.accountId,
            paymentStatus: [0, 1],
            orderBy: "periodMonth desc,periodYear desc",
          });
        } else {
          self.$refs.unpaidInvoice.getInvoices({
            invoiceNums: self.invoiceNums,
            orderBy: "periodMonth desc,periodYear desc",
          });
        }
      },
      getOutstanding() {
        const self = this;
        self.$store
          .dispatch("apis/get", {
            url: `/account/outstanding/${self.form.accountId}`,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.form.amount = response.data.outstandingPayment;
              self.form.amountDisplay = response.data.outstandingPayment;
              self.form.additionalRequiredDepositDisplay = response.data.outstandingDeposit;
              self.form.additionalRequiredDeposit = response.data.outstandingDeposit;
            }
          });
      },
    },
  };
</script>
